// @ts-nocheck
import React from "react"
import "../index.css"

import { Box, Center } from "@chakra-ui/react"
import { contents } from "../../utils/descriptions"
import { Link } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import ReactPlayer from "react-player/youtube"
import { mixPanel } from "../../mixpanel/mixpanel"

const HomeAboutUs = () => {
  const handleTrackClick = value => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", value)
      }
    }
    mixPanel.track(value)
  }
  return (
    <Box py="20">
      <Box mx={{ xl: "8rem", lg: "5rem", base: "1rem" }}>
        <Center>
          <Box
            fontWeight={600}
            fontSize={{ lg: 18, base: 16 }}
            color="#8326EA"
            letterSpacing={"wider"}
          >
            <Trans>About Us</Trans>
          </Box>
        </Center>
        <Center>
          <Box
            fontWeight={700}
            fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 20 }}
            textAlign="center"
          >
            <Trans>We Create, We Enhance, We Empower Schools</Trans>
          </Box>
        </Center>
        <Center>
          <Box
            fontWeight={700}
            fontSize={{ "2xl": 40, xl: 35, lg: 25, base: 20 }}
          >
            <Trans>We Are Wela</Trans>
          </Box>
        </Center>
        <Center my="8">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=aQ89GNyXkI0"
            controls
            volume={1}
            onPlay={() => handleTrackClick("All-in-One Video Played")}
          />
          {/* <Box w="700px" h="300px" bgColor="#3952D7"></Box> */}
        </Center>
        <Center>
          <Box
            fontSize={15}
            fontWeight={500}
            textAlign="center"
            mx={{ "2xl": "64", xl: "12", lg: "6", base: "3" }}
          >
            <Trans>{contents.aboutUs1}</Trans>
          </Box>
        </Center>
        <Center pb={{ xl: "16", base: "2" }}>
          <Link to="/AboutUs" onClick={() => handleTrackClick("About Us Tab")}>
            <Box
              as="button"
              bgColor={"#8326EA"}
              color="white"
              px="6"
              py="2"
              rounded={"full"}
              mt="8"
              fontSize={16}
            >
              <Trans>More details</Trans>
            </Box>
          </Link>
        </Center>
      </Box>
    </Box>
  )
}

export default HomeAboutUs
